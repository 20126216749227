import MainNumberDetail from "components/MainNumber/MainNumberDetail";
import React from "react";

// Dashboard
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
// .end#Dashboard

// Users
const Users = React.lazy(() => import("./components/Users/Users"));
const UserAdd = React.lazy(() => import("./components/Users/UserAdd"));
const UserDetail = React.lazy(() => import("./components/Users/UserDetail"));
const UserEdit = React.lazy(() => import("./components/Users/UserEdit"));
const UserDelete = React.lazy(() => import("./components/Users/UserDelete"));
const UserChangePassword = React.lazy(() => import("./components/Users/UserChangePassword"));
const ChangePassword = React.lazy(() => import("./components/Users/ChangePassword"));
//.end#Users

// // Letter
const Letter = React.lazy(() => import("./components/Letter/Letter"));
const LetterAdd = React.lazy(() => import("./components/Letter/LetterAdd"));
const LetterDetail = React.lazy(() => import("./components/Letter/LetterDetail"));
const LetterEdit = React.lazy(() => import("./components/Letter/LetterEdit"));
// //.end#Letter

// MainNumber
const MainNumber = React.lazy(() => import("./components/MainNumber/MainNumber"));
const MainNumberAdd = React.lazy(() => import("./components/MainNumber/MainNumberAdd"));
// const UserDetail = React.lazy(() => import("./components/Users/UserDetail"));
const MainNumberEdit = React.lazy(() => import("./components/MainNumber/MainNumberEdit"));
//.end#MainNumber

// UserGroups
const UserGroups = React.lazy(() => import("./components/UserGroups/UserGroups"));
const UserGroupsAdd = React.lazy(() => import("./components/UserGroups/UserGroupsAdd"));
const UserGroupsEdit = React.lazy(() => import("./components/UserGroups/UserGroupsEdit"));
//const UserGroupsDelete = React.lazy(() => import('./components/UserGroups/UserGroupsDelete'))
const UserGroupsDetail = React.lazy(() => import("./components/UserGroups/UserGroupsDetail"));
//.end#UsersGroups

// FunctionGroups
const FunctionGroups = React.lazy(() => import("./components/FunctionGroups/FunctionGroups"));
const FunctionGroupAdd = React.lazy(() => import("./components/FunctionGroups/FunctionGroupAdd"));
const FunctionGroupDetail = React.lazy(() =>
    import("./components/FunctionGroups/FunctionGroupDetail")
);
const FunctionGroupEdit = React.lazy(() => import("./components/FunctionGroups/FunctionGroupEdit"));
const FunctionGroupDelete = React.lazy(() =>
    import("./components/FunctionGroups/FunctionGroupDelete")
);
//.end#FunctionGroups

// Function
const Functions = React.lazy(() => import("./components/Functions/Functions"));
const FunctionAdd = React.lazy(() => import("./components/Functions/FunctionAdd"));
const FunctionDetail = React.lazy(() => import("./components/Functions/FunctionDetail"));
const FunctionEdit = React.lazy(() => import("./components/Functions/FunctionEdit"));
const FunctionDelete = React.lazy(() => import("./components/Functions/FunctionDelete"));
//.end#Function

// Menu
const Menus = React.lazy(() => import("./components/Menus/Menus"));
const MenuAdd = React.lazy(() => import("./components/Menus/MenuAdd"));
const MenuDetail = React.lazy(() => import("./components/Menus/MenuDetail"));
const MenuEdit = React.lazy(() => import("./components/Menus/MenuEdit"));
const MenuDelete = React.lazy(() => import("./components/Menus/MenuDelete"));
//.end#Menu


// Business
const Businesses = React.lazy(() => import("./components/Businesses/Businesses"));
const BusinessAdd = React.lazy(() => import("./components/Businesses/BusinessAdd"));
const BusinessDetail = React.lazy(() => import("./components/Businesses/BusinessDetail"));
const BusinessEdit = React.lazy(() => import("./components/Businesses/BusinessEdit"));
//.end#Businesses

// BusinessType
const BusinessesType = React.lazy(() => import("./components/BusinessesType/BusinessesType"));
const BusinessTypeAdd = React.lazy(() => import("./components/BusinessesType/BusinessTypeAdd"));
const BusinessTypeDetail = React.lazy(() =>
    import("./components/BusinessesType/BusinessTypeDetail")
);
const BusinessTypeEdit = React.lazy(() => import("./components/BusinessesType/BusinessTypeEdit"));
//.end#BusinessType

// Company
const Companies = React.lazy(() => import("./components/Companies/Companies"));
const CompaniesAdd = React.lazy(() => import("./components/Companies/CompaniesAdd"));
const CompaniesDetail = React.lazy(() => import("./components/Companies/CompaniesDetail"));
// end#Company

// Segment
const Segment = React.lazy(() => import("./components/Segment/Segment"));
const SegmentAdd = React.lazy(() => import("./components/Segment/SegmentAdd"));
const SegmentDetail = React.lazy(() => import("./components/Segment/SegmentDetail"));
const SegmentEdit = React.lazy(() => import("./components/Segment/SegmentEdit"));
//.end#Segment


// Order
const Order = React.lazy(() => import("./components/Order/Order"));
const OrderDetail = React.lazy(() => import("./components/Order/OrderDetail"));
const OrderAdd = React.lazy(() => import("./components/Order/OrderAdd"));
const OrderEdit = React.lazy(() => import("./components/Order/OrderEdit"));
//.end#Order

// Permissions
const Permissions = React.lazy(() => import("./components/Permissions/Permissions"));
//.end#Permissions


//Task-Workflows
const TaskWorkflows = React.lazy(() => import("./components/TaskWorkflows/TaskWorkflows"));
const TaskWorkflowDetail = React.lazy(() =>
    import("./components/TaskWorkflows/TaskWorkflowDetail")
);
const TaskWorkflowEdit = React.lazy(() => import("./components/TaskWorkflows/TaskWorkflowEdit"));
const TaskWorkflowAdd = React.lazy(() => import("./components/TaskWorkflows/TaskWorkflowAdd"));
//.end#Task-Workflows

//Task-Types
const TaskTypes = React.lazy(() => import("./components/TaskTypes/TaskTypes"));
const TaskTypeDetail = React.lazy(() => import("./components/TaskTypes/TaskTypeDetail"));
const TaskTypeEdit = React.lazy(() => import("./components/TaskTypes/TaskTypeEdit"));
const TaskTypeAdd = React.lazy(() => import("./components/TaskTypes/TaskTypeAdd"));
//.end#Task-Types


// ParamDob
const ParamDob = React.lazy(() => import("./components/ParamDob/ParamDob"));
const ParamDobAdd = React.lazy(() => import("./components/ParamDob/ParamDobAdd"));
const ParamDobDetail = React.lazy(() => import("./components/ParamDob/ParamDobDetail"));
const ParamDobEdit = React.lazy(() => import("./components/ParamDob/ParamDobEdit"));
//.end#ParamDob

// RelationShips
const RelationShips = React.lazy(() => import("./components/RelationShips/RelationShips"));
const RelationShipsAdd = React.lazy(() => import("./components/RelationShips/RelationShipsAdd"));
const RelationShipsDetail = React.lazy(() =>
    import("./components/RelationShips/RelationShipsDetail")
);
const RelationShipsEdit = React.lazy(() => import("./components/RelationShips/RelationShipsEdit"));
//.end#RelationShips

//DepartMent
const DepartMent = React.lazy(() => import("./components/DepartMent/DepartMent"));
const DepartMentAdd = React.lazy(() => import("./components/DepartMent/DepartMentAdd"));
const DepartMentDetail = React.lazy(() => import("./components/DepartMent/DepartMentDetail"));
const DepartMentEdit = React.lazy(() => import("./components/DepartMent/DepartMentEdit"));
//.end#DepartMent

//CustomerDataLead
const CustomerDataLeads = React.lazy(() =>
    import("./components/CustomerDataLeads/CustomerDataLeads")
);
const CustomerDataLeadAdd = React.lazy(() =>
    import("./components/CustomerDataLeads/CustomerDataLeadAdd")
);
const CustomerDataLeadDetail = React.lazy(() =>
    import("./components/CustomerDataLeads/CustomerDataLeadDetail")
);
const CustomerDataLeadEdit = React.lazy(() =>
    import("./components/CustomerDataLeads/CustomerDataLeadEdit")
);
//.end#CustomerDataLead

// Task
const Task = React.lazy(() => import("./components/Task/Task"));
const TaskAdd = React.lazy(() => import("./components/Task/TaskAdd"));
const TaskDetail = React.lazy(() => import("./components/Task/TaskDetail"));
const TaskEdit = React.lazy(() => import("./components/Task/TaskEdit"));
const TaskCustomerDataLeadDetail = React.lazy(() =>
    import("./components/Task/TaskCustomerDataLeadDetail")
);
//.end#Task
// Position
const Position = React.lazy(() => import("./components/Position/Position"));
const PositionAdd = React.lazy(() => import("./components/Position/PositionAdd"));
// const TaskDetail = React.lazy(() => import("./components/Task/TaskDetail"));
const PositonEdit = React.lazy(() => import("./components/Position/PositonEdit"));
//.end#Positions
// BusinessUser
const BusinessUser = React.lazy(() => import("./components/BusinessUser/BusinessUser"));
const BusinessUserAdd = React.lazy(() => import("./components/BusinessUser/BusinessUserAdd"));
//.end#BusinessUser

//CustomerDataLeadCare
const CustomerDataLeadCareByTask = React.lazy(() =>
    import("./components/CustomerDataLeads/CustomerDataLeadCareByTask")
);
//.end#CustomerDataLeadCare

// ProductCategory
const ProductCategory = React.lazy(() => import("./components/ProductCategory/ProductCategory"));
const ProductCategoryAdd = React.lazy(() =>
    import("./components/ProductCategory/ProductCategoryAdd")
);
const ProductCategoryEdit = React.lazy(() =>
    import("./components/ProductCategory/ProductCategoryEdit")
);
const ProductCategoryDetail = React.lazy(() =>
    import("./components/ProductCategory/ProductCategoryDetail")
);
//.end#ProductCategory

// ProductAttribute
const ProductAttributes = React.lazy(() =>
    import("./components/ProductAttributes/ProductAttributes")
);
const ProductAttributeAdd = React.lazy(() =>
    import("./components/ProductAttributes/ProductAttributeAdd")
);
const ProductAttributeEdit = React.lazy(() =>
    import("./components/ProductAttributes/ProductAttributeEdit")
);
const ProductAttributeDetail = React.lazy(() =>
    import("./components/ProductAttributes/ProductAttributeDetail")
);
//.end#ProductAttribute

// Product
const Product = React.lazy(() => import("./components/Product/Product"));
const ProductAdd = React.lazy(() => import("./components/Product/ProductAdd"));
const ProductDetail = React.lazy(() => import("./components/Product/ProductDetail"));
const ProductEdit = React.lazy(() => import("./components/Product/ProductEdit"));
//.end#Product


// Price
const Price = React.lazy(() => import("./components/Price/Price"));
const PriceAdd = React.lazy(() => import("./components/Price/PriceAdd"));
const PriceEdit = React.lazy(() => import("./components/Price/PriceEdit"));
const PriceDetail = React.lazy(() => import("./components/Price/PriceDetail"));
//.end#Price

// customertype
const CustomerType = React.lazy(() => import("./components/CustomerType/CustomerType"));
const CustomerTypeAdd = React.lazy(() => import("./components/CustomerType/CustomerTypeAdd"));
const CustomerTypeDetail = React.lazy(() => import("./components/CustomerType/CustomerTypeDetail"));
const CustomerTypeEdit = React.lazy(() => import("./components/CustomerType/CustomerTypeEdit"));
//.end customertype

// FormulaIngredients
const FormulaIngredients = React.lazy(() =>
    import("./components/FormulaIngredients/FormulaIngredients")
);
const FormulaIngredientsAdd = React.lazy(() =>
    import("./components/FormulaIngredients/FormulaIngredientsAdd")
);
const FormulaIngredientsDetail = React.lazy(() =>
    import("./components/FormulaIngredients/FormulaIngredientsDetail")
);
const FormulaIngredientsEdit = React.lazy(() =>
    import("./components/FormulaIngredients/FormulaIngredientsEdit")
);
//.end#FormulaIngredients

// Formula
const Formula = React.lazy(() => import("./components/Formula/Formula"));
const FormulaAdd = React.lazy(() => import("./components/Formula/FormulaAdd"));
const FormulaDetail = React.lazy(() => import("./components/Formula/FormulaDetail"));
const FormulaEdit = React.lazy(() => import("./components/Formula/FormulaEdit"));
//.end#Formula



// Admin Website: Account
const Account = React.lazy(() => import("./components/Account/Account"));
const AccountAdd = React.lazy(() => import("./components/Account/AccountAdd"));
const AccountDetail = React.lazy(() => import("./components/Account/AccountDetail"));
const AccountEdit = React.lazy(() => import("./components/Account/AccountEdit"));
//.End

// Admin Website: News
const News = React.lazy(() => import("./components/News/News"));
const NewsAdd = React.lazy(() => import("./components/News/NewsAdd"));
const NewsDetail = React.lazy(() => import("./components/News/NewsDetail"));
const NewsEdit = React.lazy(() => import("./components/News/NewsEdit"));
const NewsComment = React.lazy(() => import("./components/Comment/Comment"));
//.End

// Banner
const Banner = React.lazy(() => import("./components/Banner/Banner"));
const BannerAdd = React.lazy(() => import("./components/Banner/BannerAdd"));
const BannerDetail = React.lazy(() => import("./components/Banner/BannerDetail"));
const BannerEdit = React.lazy(() => import("./components/Banner/BannerEdit"));
//.end#Banner

// BannerType
const BannerType = React.lazy(() => import("./components/BannerType/BannerType"));
const BannerTypeAdd = React.lazy(() => import("./components/BannerType/BannerTypeAdd"));
const BannerTypeDetail = React.lazy(() => import("./components/BannerType/BannerTypeDetail"));
const BannerTypeEdit = React.lazy(() => import("./components/BannerType/BannerTypeEdit"));
//.end#BannerType

// NewsCategory
const NewsCategory = React.lazy(() => import("./components/NewsCategory/NewsCategory"));
const NewsCategoryAdd = React.lazy(() => import("./components/NewsCategory/NewsCategoryAdd"));
const NewsCategoryDetail = React.lazy(() => import("./components/NewsCategory/NewsCategoryDetail"));
const NewsCategoryEdit = React.lazy(() => import("./components/NewsCategory/NewsCategoryEdit"));
//.end#NewsCategory

// NewsStatus
const NewsStatus = React.lazy(() => import("./components/NewsStatus/NewsStatus"));
const NewsStatusAdd = React.lazy(() => import("./components/NewsStatus/NewsStatusAdd"));
const NewsStatusDetail = React.lazy(() => import("./components/NewsStatus/NewsStatusDetail"));
const NewsStatusEdit = React.lazy(() => import("./components/NewsStatus/NewsStatusEdit"));
//.end#NewsCategory

// WebsiteCategory
const WebsiteCategory = React.lazy(() => import("./components/WebsiteCategory/WebsiteCategory"));
const WebsiteCategoryAdd = React.lazy(() =>
    import("./components/WebsiteCategory/WebsiteCategoryAdd")
);
const WebsiteCategoryDetail = React.lazy(() =>
    import("./components/WebsiteCategory/WebsiteCategoryDetail")
);
const WebsiteCategoryEdit = React.lazy(() =>
    import("./components/WebsiteCategory/WebsiteCategoryEdit")
);
//.end#WebsiteCategory

// StaticContent
const StaticContent = React.lazy(() => import("./components/StaticContent/StaticContent"));
const StaticContentAdd = React.lazy(() => import("./components/StaticContent/StaticContentAdd"));
const StaticContentDetail = React.lazy(() =>
    import("./components/StaticContent/StaticContentDetail")
);
const StaticContentEdit = React.lazy(() => import("./components/StaticContent/StaticContentEdit"));
//.end#StaticContent


//Membership
const Membership = React.lazy(() => import("./components/Membership/Membership"));
//.end#Membership


// Author
const Author = React.lazy(() => import("./components/Author/Author"));
const AuthorAdd = React.lazy(() => import("./components/Author/AuthorAdd"));
const AuthorDetail = React.lazy(() => import("./components/Author/AuthorDetail"));
const AuthorEdit = React.lazy(() => import("./components/Author/AuthorEdit"));
const AuthorDelete = React.lazy(() => import("./components/Author/AuthorDelete"));
const AuthorChangePassword = React.lazy(() => import("./components/Author/AuthorChangePassword"));
// const ChangePassword = React.lazy(() => import('./components/Author/ChangePassword'));
//.end#Author

//contact customer
const ContactCustomer = React.lazy(() => import("./components/ContactCustomer/ContactCustomer"));
// const ContactCustomerAdd = React.lazy(() => import('./components/ContactCustomer/ContactCustomerAdd'));
const ContactCustomerDetail = React.lazy(() =>
    import("./components/ContactCustomer/ContactCustomerDetail")
);
// const ContactCustomerEdit = React.lazy(() => import('./components/ContactCustomer/ContactCustomerEdit'));
// const ContactCustomerDelete = React.lazy(() => import('./components/ContactCustomer/ContactCustomerDelete'));
//end#contact customer

//publishing company
const PublishingCompany = React.lazy(() =>
    import("./components/PublishingCompany/PublishingCompany")
);
const PublishingCompanyAdd = React.lazy(() =>
    import("./components/PublishingCompany/PublishingCompanyAdd")
);
const PublishingCompanyEdit = React.lazy(() =>
    import("./components/PublishingCompany/PublishingCompanyEdit")
);
const PublishingCompanyDetail = React.lazy(() =>
    import("./components/PublishingCompany/PublishingCompanyDetail")
);


//PageSetting
//Comment rating
const PageSetting = React.lazy(() => import("./components/PageSetting/PageSetting"));

//Partner
const Partner = React.lazy(() => import("./components/Partner/Partner"));
const PartnerAdd = React.lazy(() => import("./components/Partner/PartnerAdd"));
const PartnerDetail = React.lazy(() => import("./components/Partner/PartnerDetail"));
const PartnerEdit = React.lazy(() => import("./components/Partner/PartnerEdit"));
//end#Partner

//Review
const Review = React.lazy(() => import("./components/CrmReview/CrmReview"));
const ReviewAdd = React.lazy(() => import("./components/CrmReview/CrmReviewAdd"));
const ReviewDetail = React.lazy(() => import("./components/CrmReview/CrmReviewDetail"));
const ReviewEdit = React.lazy(() => import("./components/CrmReview/CrmReviewEdit"));
//end#Review

//Attributes
const Attributes = React.lazy(() => import("./components/Attributes/Attributes"));
const AttributesAdd = React.lazy(() => import("./components/Attributes/AttributesAdd"));
const AttributesDetail = React.lazy(() => import("./components/Attributes/AttributesDetail"));
const AttributesEdit = React.lazy(() => import("./components/Attributes/AttributesEdit"));
// end#Attributes

//InterPret
const InterPret = React.lazy(() => import("./components/InterPret/InterPret"));
const InterPretAdd = React.lazy(() => import("./components/InterPret/InterPretAdd"));
const InterPretDetail = React.lazy(() => import("./components/InterPret/InterPretDetail"));
const InterPretEdit = React.lazy(() => import("./components/InterPret/InterPretEdit"));
const InterPretView = React.lazy(() => import("./components/InterPret/ViewDetail"));
const ViewDetailSpectial = React.lazy(() => import("./components/InterPret/ViewDetailSpectial"));
const InterPretCopy = React.lazy(() => import("./components/InterPret/InterPretCopy"));
// end#InterPret

//InterPretDetail
const InterPretChild = React.lazy(() => import("./components/InterPret/InterPretChild"));
const InterPretChildAdd = React.lazy(() => import("./components/InterPret/InterPretChildAdd"));
const InterPretChildDetail = React.lazy(() =>
    import("./components/InterPret/InterPretChildDetail")
);
const InterPretChildEdit = React.lazy(() => import("./components/InterPret/InterPretChildEdit"));
// end#InterPretDetail

//Calculation
const Calculation = React.lazy(() => import("./components/MdCalculation/Calculation"));
const CalculationAdd = React.lazy(() => import("./components/MdCalculation/CalculationAdd"));
const CalculationDetail = React.lazy(() => import("./components/MdCalculation/CalculationDetail"));
const CalculationEdit = React.lazy(() => import("./components/MdCalculation/CalculationEdit"));
// end#Calculation

//ParamName
const ParamName = React.lazy(() => import("./components/ParamName/ParamName"));
const ParamNameAdd = React.lazy(() => import("./components/ParamName/ParamNameAdd"));
const ParamNameDetail = React.lazy(() => import("./components/ParamName/ParamNameDetail"));
const ParamNameEdit = React.lazy(() => import("./components/ParamName/ParamNameEdit"));
// end#ParamName

//AttributesGroup
const AttributesGroup = React.lazy(() => import("./components/AttributesGroup/AttributesGroup"));
const AttributesGroupAdd = React.lazy(() =>
    import("./components/AttributesGroup/AttributesGroupAdd")
);
const AttributesGroupDetail = React.lazy(() =>
    import("./components/AttributesGroup/AttributesGroupDetail")
);
const AttributesGroupEdit = React.lazy(() =>
    import("./components/AttributesGroup/AttributesGroupEdit")
);
// end#AttributesGroup

//Formula
const FormulaByDob = React.lazy(() => import("./components/FormulaByDob/FormulaByDob"));
const FormulaByDobAdd = React.lazy(() => import("./components/FormulaByDob/FormulaByDobAdd"));
const FormulaByDobDetail = React.lazy(() => import("./components/FormulaByDob/FormulaByDobDetail"));
const FormulaByDobEdit = React.lazy(() => import("./components/FormulaByDob/FormulaByDobEdit"));
// end#Formula

//FormulaByName
const FormulaByName = React.lazy(() => import("./components/FormulaByName/FormulaByName"));
const FormulaByNameAdd = React.lazy(() => import("./components/FormulaByName/FormulaByNameAdd"));
const FormulaByNameDetail = React.lazy(() =>
    import("./components/FormulaByName/FormulaByNameDetail")
);
const FormulaByNameEdit = React.lazy(() => import("./components/FormulaByName/FormulaByNameEdit"));
// end#FormulaByName

//ProductCombo
const ProductCombo = React.lazy(() => import("./components/ProductCombo/ProductCombo"));
const ProductComboAdd = React.lazy(() => import("./components/ProductCombo/ProductComboAdd"));
const ProductComboEdit = React.lazy(() => import("./components/ProductCombo/ProductComboEdit"));
const ProductComboDetail = React.lazy(() => import("./components/ProductCombo/ProductComboDetail"));
//end#ProductCombo

const SearchHistory = React.lazy(() => import("./components/SearchHistory/SearchHistory"));
// const SearchHistoryDetail = React.lazy(() =>
//     import("./components/SearchHistory/SearchHistoryDetail")
// );
//Farmous
const Farmous = React.lazy(() => import("./components/Farmous/Farmous"));
const FarmousAdd = React.lazy(() => import("./components/Farmous/FarmousAdd"));
const FarmousDetail = React.lazy(() => import("./components/Farmous/FarmousDetail"));
const FarmousEdit = React.lazy(() => import("./components/Farmous/FarmousEdit"));
// end#Farmous

//ProductComment
const CommentProduct = React.lazy(() => import("./components/ProductComment/CommentProduct"));
const CommentCombo = React.lazy(() => import("./components/ProductComment/CommentCombo"));
//.end#ProductComment

//ParamOther
const ParamOther = React.lazy(() => import("./components/ParamOther/ParamOther"));
const ParamOtherAdd = React.lazy(() => import("./components/ParamOther/ParamOtherAdd"));
const ParamOtherEdit = React.lazy(() => import("./components/ParamOther/ParamOtherEdit"));
const ParamOtherDetail = React.lazy(() => import("./components/ParamOther/ParamOtherDetail"));
//.end#ParamOther

// Discount
const Discount = React.lazy(() => import("./components/Discount/Discount"));
const DiscountAdd = React.lazy(() => import("./components/Discount/DiscountAdd"));
const DiscountDetail = React.lazy(() => import("./components/Discount/DiscountDetail"));
const DiscountEdit = React.lazy(() => import("./components/Discount/DiscountEdit"));
//.end#Discount

// PAGE
const Page = React.lazy(() => import("./components/Page/Page"));
const PageAdd = React.lazy(() => import("./components/Page/PageAdd"));
const PageDetail = React.lazy(() => import("./components/Page/PageDetail"));
const PageEdit = React.lazy(() => import("./components/Page/PageEdit"));
//.end#PAGE

// WITHDRAW REQUEST
const WithdrawRequest = React.lazy(() => import("./components/WithdrawRequest/WithdrawRequest"));
const WithdrawRequestDetail = React.lazy(() => import("./components/WithdrawRequest/WithdrawRequestDetail"));
//.end#WITHDRAW REQUEST

// AFFILIATE
const Affiliate = React.lazy(() => import("./components/Affiliate/Affiliate"));
const AffiliateAdd = React.lazy(() => import("./components/Affiliate/AffiliateAdd"));
const AffiliateEdit = React.lazy(() => import("./components/Affiliate/AffiliateEdit"));
const AffiliateReview = React.lazy(() => import("./components/Affiliate/AffiliateReview"));
const AffiliateDetail = React.lazy(() => import("./components/Affiliate/AffiliateDetail"));
const AffiliateRequest = React.lazy(() => import("./components/Affiliate/AffiliateRequest"));
//.end#AFFILIATE

// POLICYCOMMISION
const AffPolicyCommision = React.lazy(() => import("./components/AffPolicyCommision/AffPolicyCommision"));
const AffPolicyCommisionAdd = React.lazy(() => import("./components/AffPolicyCommision/AffPolicyCommisionAdd"));
const AffPolicyCommisionEdit = React.lazy(() => import("./components/AffPolicyCommision/AffPolicyCommisionEdit"));
const AffPolicyCommisionDetail = React.lazy(() => import("./components/AffPolicyCommision/AffPolicyCommisionDetail"));
//.end#POLICYCOMMISION



// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    // dashboard
    {
        path: "/",
        exact: true,
        name: "Trang chủ",
        function: "DASHBOARD_VIEW",
        component: Dashboard,
        // component: () => {
        //   window._$g.rdr("/dashboard");
        //   return null;
        // },
    },
    //.end#dashboard
    // Users
    {
        path: "/users",
        exact: true,
        name: "Danh sách nhân viên",
        function: "SYS_USER_VIEW",
        component: Users,
    },
    {
        path: "/users/add",
        exact: true,
        name: "Thêm mới",
        function: "SYS_USER_ADD",
        component: UserAdd,
    },
    {
        path: "/users/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "SYS_USER_VIEW",
        component: UserDetail,
    },
    {
        path: "/users/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SYS_USER_EDIT",
        component: UserEdit,
    },
    {
        path: "/users/delete/:id",
        exact: true,
        name: "Xóa",
        function: "SYS_USER_DEL",
        component: UserDelete,
    },
    {
        path: "/users/change-password/:id",
        exact: true,
        name: "Thay đổi mật khẩu",
        function: "SYS_USER_PASSWORD",
        component: UserChangePassword,
    },
    {
        path: "/change-password",
        exact: true,
        name: "Thay đổi mật khẩu",
        function: null,
        component: ChangePassword,
    },
    //.end#Users

    //UserGroup
    {
        path: "/user-groups",
        exact: true,
        name: "Danh sách nhóm người dùng",
        function: "SYS_USERGROUP_VIEW",
        component: UserGroups,
    },
    {
        path: "/user-groups/add",
        exact: true,
        name: "Thêm mới",
        function: "SYS_USERGROUP_ADD",
        component: UserGroupsAdd,
    },
    {
        path: "/user-groups/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "SYS_USERGROUP_VIEW",
        component: UserGroupsDetail,
    },
    {
        path: "/user-groups/delete/:id",
        exact: true,
        name: "Xóa",
        function: "SYS_USERGROUP_DEL",
    },
    {
        path: "/user-groups/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SYS_USERGROUP_EDIT",
        component: UserGroupsEdit,
    },
    //.end#UserGroup

    //FunctionGroups
    {
        path: "/function-groups",
        exact: true,
        name: "Danh sách nhóm quyền",
        function: "SYS_FUNCTIONGROUP_VIEW",
        component: FunctionGroups,
    },
    {
        path: "/function-groups/add",
        exact: true,
        name: "Thêm mới",
        function: "SYS_FUNCTIONGROUP_ADD",
        component: FunctionGroupAdd,
    },
    {
        path: "/function-groups/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SYS_FUNCTIONGROUP_EDIT",
        component: FunctionGroupEdit,
    },
    {
        path: "/function-groups/delete/:id",
        exact: true,
        name: "Xóa",
        function: "SYS_FUNCTIONGROUP_DEL",
        component: FunctionGroupDelete,
    },
    {
        path: "/function-groups/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "SYS_FUNCTIONGROUP_VIEW",
        component: FunctionGroupDetail,
    },
    //.end#FunctionGroups

    //Functions
    {
        path: "/functions",
        exact: true,
        name: "Danh sách quyền",
        function: "SYS_FUNCTION_VIEW",
        component: Functions,
    },
    {
        path: "/functions/add",
        exact: true,
        name: "Thêm mới",
        function: "SYS_FUNCTION_ADD",
        component: FunctionAdd,
    },
    {
        path: "/functions/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SYS_FUNCTION_EDIT",
        component: FunctionEdit,
    },
    {
        path: "/functions/delete/:id",
        exact: true,
        name: "Xóa",
        function: "SYS_FUNCTION_DEL",
        component: FunctionDelete,
    },
    {
        path: "/functions/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "SYS_FUNCTION_VIEW",
        component: FunctionDetail,
    },
    //.end#Functions

    //Menus
    {
        path: "/menus",
        exact: true,
        name: "Danh sách menu",
        function: "SYS_MENU_VIEW",
        component: Menus,
    },
    {
        path: "/menus/add",
        exact: true,
        name: "Thêm mới",
        function: "SYS_MENU_ADD",
        component: MenuAdd,
    },
    {
        path: "/menus/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SYS_MENU_EDIT",
        component: MenuEdit,
    },
    {
        path: "/menus/delete/:id",
        exact: true,
        name: "Xóa",
        function: "SYS_MENU_DEL",
        component: MenuDelete,
    },
    {
        path: "/menus/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "SYS_MENU_VIEW",
        component: MenuDetail,
    },
    //.end#Menus

    

    // Business
    {
        path: "/businesses",
        exact: true,
        name: "Danh sách cơ sở",
        function: "AM_BUSINESS_VIEW",
        component: Businesses,
    },
    {
        path: "/businesses/add",
        exact: true,
        name: "Thêm mới",
        function: "AM_BUSINESS_ADD",
        component: BusinessAdd,
    },
    {
        path: "/businesses/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "AM_BUSINESS_VIEW",
        component: BusinessDetail,
    },
    {
        path: "/businesses/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "AM_BUSINESS_EDIT",
        component: BusinessEdit,
    },
    //.end#Business

    // BusinessType
    {
        path: "/businesses-type",
        exact: true,
        name: "Danh sách loại cơ sở",
        function: "AM_BUSINESSTYPE_VIEW",
        component: BusinessesType,
    },
    {
        path: "/businesses-type/add",
        exact: true,
        name: "Thêm mới",
        function: "AM_BUSINESSTYPE_ADD",
        component: BusinessTypeAdd,
    },
    {
        path: "/businesses-type/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "AM_BUSINESSTYPE_VIEW",
        component: BusinessTypeDetail,
    },
    {
        path: "/businesses-type/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "AM_BUSINESSTYPE_EDIT",
        component: BusinessTypeEdit,
    },
    //.end#BusinessType

    // Companies
    {
        path: "/companies",
        exact: true,
        name: "Danh sách công ty",
        function: "AM_COMPANY_VIEW",
        component: Companies,
    },
    {
        path: "/companies/add",
        exact: true,
        name: "Thêm mới",
        function: "AM_COMPANY_ADD",
        component: CompaniesAdd,
    },
    {
        path: "/companies/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "AM_COMPANY_VIEW",
        component: CompaniesDetail,
    },
    {
        path: "/companies/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "AM_COMPANY_EDIT",
        component: CompaniesAdd,
    },
    // .end#Companies

    // permissions
    {
        path: "/permissions",
        exact: true,
        name: "Phân quyền",
        function: "PERMISSION_VIEW",
        component: Permissions,
    },
    //.end#permissions

   
    // Segment
    {
        path: "/segment",
        exact: true,
        name: "Danh sách phân khúc",
        function: "CRM_SEGMENT_VIEW",
        component: Segment,
    },
    {
        path: "/segment/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_SEGMENT_ADD",
        component: SegmentAdd,
    },
    {
        path: "/segment/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_SEGMENT_VIEW",
        component: SegmentDetail,
    },
    {
        path: "/segment/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_SEGMENT_EDIT",
        component: SegmentEdit,
    },
    //.end#Segment

    //task-workflow
    {
        path: "/task-workflows",
        exact: true,
        name: "Danh sách bước xử lý công việc",
        function: "CRM_TASKWORKFLOW_VIEW",
        component: TaskWorkflows,
    },
    {
        path: "/task-workflows/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_TASKWORKFLOW_ADD",
        component: TaskWorkflowAdd,
    },
    {
        path: "/task-workflows/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_TASKWORKFLOW_VIEW",
        component: TaskWorkflowDetail,
    },
    {
        path: "/task-workflows/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_TASKWORKFLOW_EDIT",
        component: TaskWorkflowEdit,
    },
    //.end#task-workflow

    //task-type
    {
        path: "/task-types",
        exact: true,
        name: "Danh sách loại công việc",
        function: "CRM_TASKTYPE_VIEW",
        component: TaskTypes,
    },
    {
        path: "/task-types/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_TASKTYPE_ADD",
        component: TaskTypeAdd,
    },
    {
        path: "/task-types/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_TASKTYPE_VIEW",
        component: TaskTypeDetail,
    },
    {
        path: "/task-types/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_TASKTYPE_EDIT",
        component: TaskTypeEdit,
    },
    //.end#task-type

    //DepartMent
    {
        path: "/department",
        exact: true,
        name: "Danh sách phòng ban",
        function: "MD_DEPARTMENT_VIEW",
        component: DepartMent,
    },
    {
        path: "/department/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_DEPARTMENT_ADD",
        component: DepartMentAdd,
    },
    {
        path: "/department/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_DEPARTMENT_VIEW",
        component: DepartMentDetail,
    },
    {
        path: "/department/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_DEPARTMENT_EDIT",
        component: DepartMentEdit,
    },
    //.end#DepartMent

    // Task
    {
        path: "/task",
        exact: true,
        name: "Danh sách công việc",
        function: "CRM_TASK_VIEW",
        component: Task,
    },
    {
        path: "/task/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_TASK_ADD",
        component: TaskAdd,
    },
    {
        path: "/task/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_TASK_VIEW",
        component: TaskDetail,
    },
    {
        path: "/task/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_TASK_EDIT",
        component: TaskEdit,
    },
    {
        path: "/task/customers/:id",
        exact: true,
        name: "Danh sách khách hàng thuộc công việc",
        function: "CRM_TASK_VIEW",
        component: TaskCustomerDataLeadDetail,
    },
    //.end#Task

    //CustomerDataLead
    {
        path: "/customer-data-leads",
        exact: true,
        name: "Danh sách khách hàng",
        function: "CRM_CUSDATALEADS_VIEW",
        component: CustomerDataLeads,
    },
    {
        path: "/customer-data-leads/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_CUSDATALEADS_ADD",
        component: CustomerDataLeadAdd,
    },
    {
        path: "/customer-data-leads/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_CUSDATALEADS_VIEW",
        component: CustomerDataLeadDetail,
    },
    {
        path: "/customer-data-leads/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_CUSDATALEADS_EDIT",
        component: CustomerDataLeadEdit,
    },
    {
        path: "/task/customers/:taskid/:id",
        exact: true,
        name: "Chi tiết chăm sóc khách hàng",
        function: "CRM_CUSDATALEADSDETAIL_VIEW",
        component: CustomerDataLeadCareByTask,
    },
    //.end#CustomerDataLeadCare

    {
        path: "/business-user",
        exact: true,
        name: "Phân nhân viên - Cơ sở",
        function: "SYS_BUSINESS_USER_VIEW",
        component: BusinessUser,
    },
    {
        path: "/business-user/add/:businessId",
        exact: true,
        name: "Thêm nhân viên vào cơ sở",
        function: "SYS_BUSINESS_USER_ADD",
        component: BusinessUserAdd,
    },

    // ProductCategory
    {
        path: "/product-category",
        exact: true,
        name: "Danh mục sản phẩm",
        function: "MD_PRODUCTCATEGORY_VIEW",
        component: ProductCategory,
    },
    {
        path: "/product-category/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PRODUCTCATEGORY_ADD",
        component: ProductCategoryAdd,
    },
    {
        path: "/product-category/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PRODUCTCATEGORY_EDIT",
        component: ProductCategoryEdit,
    },
    {
        path: "/product-category/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PRODUCTCATEGORY_VIEW",
        component: ProductCategoryDetail,
    },
    //.end#ProductCategory

    // ProductAttribute
    {
        path: "/product-attributes",
        exact: true,
        name: "Thuộc tính sản phẩm",
        function: "PRO_PRODUCTATTRIBUTE_VIEW",
        component: ProductAttributes,
    },
    {
        path: "/product-attributes/add",
        exact: true,
        name: "Thêm mới",
        function: "PRO_PRODUCTATTRIBUTE_ADD",
        component: ProductAttributeAdd,
    },
    {
        path: "/product-attributes/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "PRO_PRODUCTATTRIBUTE_EDIT",
        component: ProductAttributeEdit,
    },
    {
        path: "/product-attributes/details/:id",
        exact: true,
        name: "Chi tiết",
        function: "PRO_PRODUCTATTRIBUTE_VIEW",
        component: ProductAttributeDetail,
    },
    //.end#ProductAttribute

    // Product
    {
        path: "/product",
        exact: true,
        name: "Danh sách sản phẩm",
        function: "MD_PRODUCT_VIEW",
        component: Product,
    },
    {
        path: "/product/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PRODUCT_ADD",
        component: ProductAdd,
    },
    {
        path: "/product/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PRODUCT_VIEW",
        component: ProductDetail,
    },
    {
        path: "/product/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PRODUCT_EDIT",
        component: ProductEdit,
    },
    //.end#Product

    

    // Price
    {
        path: "/price",
        exact: true,
        name: "Danh sách giá sản phẩm",
        function: "SL_PRICE_VIEW",
        component: Price,
    },
    {
        path: "/price/add",
        exact: true,
        name: "Làm giá",
        function: "SL_PRICE_ADD",
        component: PriceAdd,
    },
    {
        path: "/price/detail/:id",
        exact: true,
        name: "Chi tiết giá",
        function: "SL_PRICE_VIEW",
        component: PriceDetail,
    },
    {
        path: "/price/edit/:id",
        exact: true,
        name: "Chỉnh sửa giá",
        function: "SL_PRICE_EDIT",
        component: PriceEdit,
    },
    //.end#Prices

    //CustomerType
    {
        path: "/customer-type",
        exact: true,
        name: "Danh sách loại khách hàng",
        function: "CRM_CUSTOMERTYPE_VIEW",
        component: CustomerType,
    },
    {
        path: "/customer-type/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_CUSTOMERTYPE_ADD",
        component: CustomerTypeAdd,
    },
    {
        path: "/customer-type/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_CUSTOMERTYPE_VIEW",
        component: CustomerTypeDetail,
    },
    {
        path: "/customer-type/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_CUSTOMERTYPE_EDIT",
        component: CustomerTypeEdit,
    },
    //.end CustomerType


    // Admin Website: Account
    {
        path: "/account",
        exact: true,
        name: "Danh sách khách hàng",
        function: "CRM_ACCOUNT_VIEW",
        component: Account,
    },
    {
        path: "/account/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_ACCOUNT_ADD",
        component: AccountAdd,
    },
    {
        path: "/account/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_ACCOUNT_VIEW",
        component: AccountDetail,
    },
    {
        path: "/account/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_ACCOUNT_EDIT",
        component: AccountEdit,
    },
    // {
    //   path: "/account/account-change-password/:id",
    //   exact: true,
    //   name: "Thay đổi mật khẩu",
    //   function: "SYS_ACCOUNT_PASSWORD",
    //   component: AccountChangePassword,
    // },
    // {
    //   path: "/acc-change-password",
    //   exact: true,
    //   name: "Thay đổi mật khẩu",
    //   function: null,
    //   component: AccChangePassword,
    // },
    //.End
    // Admin Website: News
    {
        path: "/news",
        exact: true,
        name: "Danh sách bài viết",
        function: "NEWS_NEWS_VIEW",
        component: News,
    },
    {
        path: "/news/add",
        exact: true,
        name: "Thêm mới",
        function: "NEWS_NEWS_ADD",
        component: NewsAdd,
    },
    {
        path: "/news/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "NEWS_NEWS_VIEW",
        component: NewsDetail,
    },
    {
        path: "/news/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "NEWS_NEWS_EDIT",
        component: NewsEdit,
    },
    {
        path: "/news/comment/:id",
        exact: true,
        name: "Bình luận",
        function: "NEWS_NEWS_COMMENT_VIEW",
        component: NewsComment,
    },
    //.End

    // Banner
    {
        path: "/banner",
        exact: true,
        name: "Banner",
        function: "CMS_BANNER_VIEW",
        component: Banner,
    },
    {
        path: "/banner/add",
        exact: true,
        name: "Thêm mới",
        function: "CMS_BANNER_ADD",
        component: BannerAdd,
    },
    {
        path: "/banner/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CMS_BANNER_VIEW",
        component: BannerDetail,
    },
    {
        path: "/banner/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CMS_BANNER_EDIT",
        component: BannerEdit,
    },
    //.end#BannerType

    // BannerType
    {
        path: "/banner-type",
        exact: true,
        name: "Loại banner",
        function: "CMS_BANNERTYPE_VIEW",
        component: BannerType,
    },
    {
        path: "/banner-type/add",
        exact: true,
        name: "Thêm mới",
        function: "CMS_BANNERTYPE_ADD",
        component: BannerTypeAdd,
    },
    {
        path: "/banner-type/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CMS_BANNERTYPE_VIEW",
        component: BannerTypeDetail,
    },
    {
        path: "/banner-type/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CMS_BANNERTYPE_EDIT",
        component: BannerTypeEdit,
    },
    //.end#BannerType

    // NewsCategory
    {
        path: "/news-category",
        exact: true,
        name: "Chuyên mục tin tức",
        function: "NEWS_NEWSCATEGORY_VIEW",
        component: NewsCategory,
    },
    {
        path: "/news-category/add",
        exact: true,
        name: "Thêm mới",
        function: "NEWS_NEWSCATEGORY_ADD",
        component: NewsCategoryAdd,
    },
    {
        path: "/news-category/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "NEWS_NEWSCATEGORY_VIEW",
        component: NewsCategoryDetail,
    },
    {
        path: "/news-category/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "NEWS_NEWSCATEGORY_EDIT",
        component: NewsCategoryEdit,
    },
    //.end#NewsCategory

    // NewsStatus
    {
        path: "/news-status",
        exact: true,
        name: "Trạng thái tin tức",
        function: "NEWS_NEWSSTATUS_VIEW",
        component: NewsStatus,
    },
    {
        path: "/news-status/add",
        exact: true,
        name: "Thêm mới",
        function: "NEWS_NEWSSTATUS_ADD",
        component: NewsStatusAdd,
    },
    {
        path: "/news-status/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "NEWS_NEWSSTATUS_VIEW",
        component: NewsStatusDetail,
    },
    {
        path: "/news-status/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "NEWS_NEWSSTATUS_EDIT",
        component: NewsStatusEdit,
    },
    //.end#NewsStatus

    
    // WebsiteCategory
    {
        path: "/website-category",
        exact: true,
        name: "Danh mục website",
        function: "CMS_WEBSITECATE_VIEW",
        component: WebsiteCategory,
    },
    {
        path: "/website-category/add",
        exact: true,
        name: "Thêm mới",
        function: "CMS_WEBSITECATE_ADD",
        component: WebsiteCategoryAdd,
    },
    {
        path: "/website-category/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CMS_WEBSITECATE_VIEW",
        component: WebsiteCategoryDetail,
    },
    {
        path: "/website-category/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CMS_WEBSITECATE_EDIT",
        component: WebsiteCategoryEdit,
    },
    //.end#WebsiteCategory

    

    // StaticContent
    {
        path: "/static-content",
        exact: true,
        name: "Nội dung trang tĩnh",
        function: "CMS_STATICCONTENT_VIEW",
        component: StaticContent,
    },
    {
        path: "/static-content/add",
        exact: true,
        name: "Thêm mới",
        function: "CMS_STATICCONTENT_ADD",
        component: StaticContentAdd,
    },
    {
        path: "/static-content/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CMS_STATICCONTENT_VIEW",
        component: StaticContentDetail,
    },
    {
        path: "/static-content/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CMS_STATICCONTENT_EDIT",
        component: StaticContentEdit,
    },
    //.end#StaticContent

    

    //Membership
    {
        path: "/membership",
        exact: true,
        name: "Danh sách thành viên",
        function: "CRM_MEMBERSHIP_VIEW",
        component: Membership,
    },
    //.end#Membership

    // Author
    {
        path: "/author",
        exact: true,
        name: "Danh sách tác giả",
        function: "CRM_AUTHOR_VIEW",
        component: Author,
    },
    {
        path: "/author/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_AUTHOR_ADD",
        component: AuthorAdd,
    },
    {
        path: "/author/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_AUTHOR_VIEW",
        component: AuthorDetail,
    },
    {
        path: "/author/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_AUTHOR_EDIT",
        component: AuthorEdit,
    },
    {
        path: "/author/delete/:id",
        exact: true,
        name: "Xóa",
        function: "CRM_AUTHOR_DEL",
        component: AuthorDelete,
    },
    {
        path: "/author/change-password/:id",
        exact: true,
        name: "Thay đổi mật khẩu",
        function: "CRM_AUTHOR_PASSWORD",
        component: AuthorChangePassword,
    },
    // { path: '/author/change-password', exact: true, name: 'Thay đổi mật khẩu', function: null, component: ChangePassword },
    //.end#Author

    
    // Contact cusomer
    {
        path: "/contact",
        exact: true,
        name: "Danh sách liên hệ",
        function: "CMS_CONTACT_VIEW",
        component: ContactCustomer,
    },
    {
        path: "/contact/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CMS_CONTACT_VIEW",
        component: ContactCustomerDetail,
    },
    //.end#Contact cusomer

    //publishing company
    {
        path: "/publishing-company",
        exact: true,
        name: "Danh sách nhà xuất bản",
        function: "MD_PUBLISHINGCOMPANY_VIEW",
        component: PublishingCompany,
    },
    {
        path: "/publishing-company/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PUBLISHINGCOMPANY_ADD",
        component: PublishingCompanyAdd,
    },
    {
        path: "/publishing-company/edit/:id",
        exact: true,
        name: "Tạo mới",
        function: "MD_PUBLISHINGCOMPANY_EDIT",
        component: PublishingCompanyEdit,
    },
    {
        path: "/publishing-company/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PUBLISHINGCOMPANY_VIEW",
        component: PublishingCompanyDetail,
    },
    
    // PageSetting
    {
        path: "/page-setting",
        exact: true,
        name: "Cài đặt trang web",
        function: "SYS_APPCONFIG_VIEW",
        component: PageSetting,
    },
    // end#PageSeting

    // Partner
    {
        path: "/partner",
        exact: true,
        name: "Danh sách đối tác",
        function: "MD_PARTNER_VIEW",
        component: Partner,
    },
    {
        path: "/partner/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PARTNER_ADD",
        component: PartnerAdd,
    },
    {
        path: "/partner/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PARTNER_VIEW",
        component: PartnerDetail,
    },
    {
        path: "/partner/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PARTNER_EDIT",
        component: PartnerEdit,
    },
    // end#Partner
    // Review
    {
        path: "/review",
        exact: true,
        name: "Dánh sách đánh giá",
        function: "CRM_REVIEW_VIEW",
        component: Review,
    },
    {
        path: "/review/add",
        exact: true,
        name: "Thêm mới",
        function: "CRM_REVIEW_ADD",
        component: ReviewAdd,
    },
    {
        path: "/review/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "CRM_REVIEW_VIEW",
        component: ReviewDetail,
    },
    {
        path: "/review/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "CRM_REVIEW_EDIT",
        component: ReviewEdit,
    },
    // end#Review
    // Review
    {
        path: "/position",
        exact: true,
        name: "Chức vụ",
        function: "MD_POSITION_VIEW",
        component: Position,
    },
    {
        path: "/position/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_POSITION_ADD",
        component: PositionAdd,
    },
    // {
    //   path: "/review/detail/:id",
    //   exact: true,
    //   name: "Chi tiết",
    //   function: "MD_POSITION_VIEW",
    //   component: ReviewDetail,
    // },
    {
        path: "/position/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_POSITION_EDIT",
        component: PositonEdit,
    },
    // end#Review
    // MainNumber
    {
        path: "/main-number",
        exact: true,
        name: "Danh sách giá trị",
        function: "FOR_MAINNUMBER_VIEW",
        component: MainNumber,
    },
    {
        path: "/main-number/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_MAINNUMBER_ADD",
        component: MainNumberAdd,
    },
    {
        path: "/main-number/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_MAINNUMBER_VIEW",
        component: MainNumberDetail,
    },
    {
        path: "/main-number/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_MAINNUMBER_EDIT",
        component: MainNumberEdit,
    },
    // end#MainNumber

    //Attributes
    {
        path: "/attributes",
        exact: true,
        name: "Danh sách thuộc tính",
        function: "FOR_ATTRIBUTES_VIEW",
        component: Attributes,
    },
    {
        path: "/attributes/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_ATTRIBUTES_ADD",
        component: AttributesAdd,
    },
    {
        path: "/attributes/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_ATTRIBUTES_VIEW",
        component: AttributesDetail,
    },
    {
        path: "/attributes/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_ATTRIBUTES_EDIT",
        component: AttributesEdit,
    },
    // end#Attributes

    //Calculation
    {
        path: "/calculation",
        exact: true,
        name: "Danh sách phép tính",
        function: "MD_CALCULATION_VIEW",
        component: Calculation,
    },
    {
        path: "/calculation/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_CALCULATION_ADD",
        component: CalculationAdd,
    },
    {
        path: "/calculation/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_CALCULATION_VIEW",
        component: CalculationDetail,
    },
    {
        path: "/calculation/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_CALCULATION_EDIT",
        component: CalculationEdit,
    },
    // end#Calculation

    //Letter
    {
        path: "/letter",
        exact: true,
        name: "Bảng chữ cái",
        function: "MD_LETTER_VIEW",
        component: Letter,
    },
    {
        path: "/letter/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_LETTER_ADD",
        component: LetterAdd,
    },
    {
        path: "/letter/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_LETTER_VIEW",
        component: LetterDetail,
    },
    {
        path: "/letter/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_LETTER_EDIT",
        component: LetterEdit,
    },
    // end#Letter

    //ParamName
    {
        path: "/param-name",
        exact: true,
        name: "Danh sách biến số tên",
        function: "MD_PARAMNAME_VIEW",
        component: ParamName,
    },
    {
        path: "/param-name/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PARAMNAME_ADD",
        component: ParamNameAdd,
    },
    {
        path: "/param-name/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PARAMNAME_VIEW",
        component: ParamNameDetail,
    },
    {
        path: "/param-name/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PARAMNAME_EDIT",
        component: ParamNameEdit,
    },
    // end#ParamName

    //AttributesGroup
    {
        path: "/attributes-group",
        exact: true,
        name: "Danh sách chỉ số",
        function: "FOR_ATTRIBUTESGROUP_VIEW",
        component: AttributesGroup,
    },
    {
        path: "/attributes-group/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_ATTRIBUTESGROUP_ADD",
        component: AttributesGroupAdd,
    },
    {
        path: "/attributes-group/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_ATTRIBUTESGROUP_VIEW",
        component: AttributesGroupDetail,
    },
    {
        path: "/attributes-group/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_ATTRIBUTESGROUP_EDIT",
        component: AttributesGroupEdit,
    },
    // end#AttributesGroup

    //param-type
    {
        path: "/param-dob",
        exact: true,
        name: "Danh sách biến số ngày sinh",
        function: "MD_PARAMDOB_VIEW",
        component: ParamDob,
    },
    {
        path: "/param-dob/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PARAMDOB_ADD",
        component: ParamDobAdd,
    },
    {
        path: "/param-dob/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PARAMDOB_VIEW",
        component: ParamDobDetail,
    },
    {
        path: "/param-dob/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PARAMDOB_EDIT",
        component: ParamDobEdit,
    },
    // end#param-type

    //formula
    {
        path: "/formula-by-dob",
        exact: true,
        name: "Danh sách công thức theo ngày sinh",
        function: "FOR_FORMULABYDOB_VIEW",
        component: FormulaByDob,
    },
    {
        path: "/formula-by-dob/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_FORMULABYDOB_ADD",
        component: FormulaByDobAdd,
    },
    {
        path: "/formula-by-dob/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_FORMULABYDOB_VIEW",
        component: FormulaByDobDetail,
    },
    {
        path: "/formula-by-dob/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_FORMULABYDOB_EDIT",
        component: FormulaByDobEdit,
    },
    // end#formula

    //formula-by-name
    {
        path: "/formula-by-name",
        exact: true,
        name: "Danh sách công thức theo tên",
        function: "FOR_FORMULABYNAME_VIEW",
        component: FormulaByName,
    },
    {
        path: "/formula-by-name/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_FORMULABYNAME_ADD",
        component: FormulaByNameAdd,
    },
    {
        path: "/formula-by-name/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_FORMULABYNAME_VIEW",
        component: FormulaByNameDetail,
    },
    {
        path: "/formula-by-name/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_FORMULABYNAME_EDIT",
        component: FormulaByNameEdit,
    },
    // end#formula-by-name

    //relationShips
    {
        path: "/relationShips",
        exact: true,
        name: "Danh sách mối quan hệ",
        function: "MD_RELATIONSHIPS_VIEW",
        component: RelationShips,
    },
    {
        path: "/relationShips/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_RELATIONSHIPS_ADD",
        component: RelationShipsAdd,
    },
    {
        path: "/relationShips/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_RELATIONSHIPS_VIEW",
        component: RelationShipsDetail,
    },
    {
        path: "/relationShips/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_RELATIONSHIPS_EDIT",
        component: RelationShipsEdit,
    },
    // end#relationShips

    //ProductCombo
    {
        path: "/product-combo",
        exact: true,
        name: "Danh sách Combo",
        function: "PRO_COMBOS_VIEW",
        component: ProductCombo,
    },
    {
        path: "/product-combo/add",
        exact: true,
        name: "Thêm mới",
        function: "PRO_COMBOS_ADD",
        component: ProductComboAdd,
    },
    {
        path: "/product-combo/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "PRO_COMBOS_VIEW",
        component: ProductComboDetail,
    },
    {
        path: "/product-combo/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "PRO_COMBOS_EDIT",
        component: ProductComboEdit,
    },
    // end#ProductCombo
    //interpret
    {
        path: "/interpret/show-list-child/:id",
        exact: true,
        name: "Danh sách luận giải",
        function: "FOR_INTERPRET_VIEW",
        component: InterPret,
    },
    {
        path: "/interpret",
        exact: true,
        name: "Danh sách luận giải",
        function: "FOR_INTERPRET_VIEW",
        component: InterPret,
    },

    {
        path: "/interpret/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_INTERPRET_ADD",
        component: InterPretAdd,
    },
    {
        path: "/interpret/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_INTERPRET_VIEW",
        component: InterPretDetail,
    },
    {
        path: "/interpret/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_INTERPRET_EDIT",
        component: InterPretEdit,
    },
    {
        path: "/interpret/detail-web/:id",
        exact: true,
        // name: "Chi tiết",
        function: "FOR_INTERPRET_VIEW_DETAIL_WEB",
        component: InterPretView,
    },
    {
        path: "/interpret/detail-web-spectial/:id",
        exact: true,
        // name: "Chi tiết",
        function: "FOR_INTERPRET_VIEW_DETAIL_WEB",
        component: ViewDetailSpectial,
    },
    {
        path: "/interpret/copy/:id",
        exact: true,
        name: "Sao chép",
        function: "FOR_INTERPRET_EDIT",
        component: InterPretCopy,
    },
    // end#interpret

    //InterPretChild
    // {
    //   path: "/interpret/interpret-detail/:id",
    //   exact: true,
    //   name: "Danh sách luận giải chi tiết",
    //   function: "FOR_INTERPRET_VIEW",
    //   component: InterPretChild,
    // },
    {
        path: "/interpret/d-add/:id",
        exact: true,
        name: "Thêm mới",
        function: "FOR_INTERPRET_ADD",
        component: InterPretChildAdd,
    },
    {
        path: "/interpret/d-detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_INTERPRET_VIEW",
        component: InterPretChildDetail,
    },
    {
        path: "/interpret/d-edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_INTERPRET_EDIT",
        component: InterPretChildEdit,
    },
    // end#InterPretChild
    //order
    {
        path: "/order",
        exact: true,
        name: "Danh sách đơn hàng",
        function: "SL_ORDER_VIEW",
        component: Order,
    },
    {
        path: "/order/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "SL_ORDER_VIEW",
        component: OrderDetail,
    },
    {
        path: "/order/add",
        exact: true,
        name: "Thêm mới",
        function: "SL_ORDER_ADD",
        component: OrderAdd,
    },
    {
        path: "/order/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "SL_ORDER_EDIT",
        component: OrderEdit,
    },

    // end#order

    // searchHistory
    {
        path: "/search-history",
        exact: true,
        name: "Lịch sử tra cứu",
        function: "CUS_SEARCH_HISTORY_VIEW",
        component: SearchHistory,
    },
    // {
    //     path: "/search-history/detail/:id",
    //     exact: true,
    //     name: "Chi tiết",
    //     function: "CUS_SEARCH_HISTORY_VIEW",
    //     component: SearchHistoryDetail,
    // },
    //end searchHistory
    //formula-ingredients
    {
        path: "/formula-ingredients",
        exact: true,
        name: "Danh sách thành phần",
        function: "FOR_FORMULAINGREDIENTS_VIEW",
        component: FormulaIngredients,
    },
    {
        path: "/formula-ingredients/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_FORMULAINGREDIENTS_ADD",
        component: FormulaIngredientsAdd,
    },
    {
        path: "/formula-ingredients/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_FORMULAINGREDIENTS_VIEW",
        component: FormulaIngredientsDetail,
    },
    {
        path: "/formula-ingredients/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_FORMULAINGREDIENTS_EDIT",
        component: FormulaIngredientsEdit,
    },
    // end#FormulaIngredients
    //Formula
    {
        path: "/formula",
        exact: true,
        name: "Danh sách công thức",
        function: "FOR_FORMULA_VIEW",
        component: Formula,
    },
    {
        path: "/formula/add",
        exact: true,
        name: "Thêm mới",
        function: "FOR_FORMULA_ADD",
        component: FormulaAdd,
    },
    {
        path: "/formula/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "FOR_FORMULA_VIEW",
        component: FormulaDetail,
    },
    {
        path: "/formula/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "FOR_FORMULA_EDIT",
        component: FormulaEdit,
    },
    // end#Formula
    //Farmous
    {
        path: "/famous",
        exact: true,
        name: "Danh sách người nổi tiếng",
        function: "MD_FAMOUS_VIEW",
        component: Farmous,
    },
    {
        path: "/famous/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_FAMOUS_ADD",
        component: FarmousAdd,
    },
    {
        path: "/famous/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_FAMOUS_VIEW",
        component: FarmousDetail,
    },
    {
        path: "/famous/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_FAMOUS_EDIT",
        component: FarmousEdit,
    },
    // end#Farmous

    //ProductComment
    {
        path: "/product/comment/:id",
        exact: true,
        name: "Danh sách bình luận",
        function: "PRO_COMMENT_VIEW",
        component: CommentProduct,
    },

    {
        path: "/product-combo/comment/:id",
        exact: true,
        name: "Danh sách bình luận",
        function: "PRO_COMMENT_VIEW",
        component: CommentCombo,
    },
    //.end#ProductComment

    //ParamOther
    {
        path: "/param-other",
        exact: true,
        name: "Danh sách biến số khác",
        function: "MD_PARAMOTHER_VIEW",
        component: ParamOther,
    },
    {
        path: "/param-other/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PARAMOTHER_ADD",
        component: ParamOtherAdd,
    },
    {
        path: "/param-other/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PARAMOTHER_VIEW",
        component: ParamOtherDetail,
    },
    {
        path: "/param-other/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PARAMOTHER_EDIT",
        component: ParamOtherEdit,
    },
    // end#ParamOther

    //discount
    {
        path: "/discount",
        exact: true,
        name: "Mã Khuyến mãi",
        function: "PRO_DISCOUNT_VIEW",
        component: Discount,
    },
    {
        path: "/discount/add",
        exact: true,
        name: "Thêm mới",
        function: "PRO_DISCOUNT_ADD",
        component: DiscountAdd,
    },
    {
        path: "/discount/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "PRO_DISCOUNT_VIEW",
        component: DiscountDetail,
    },
    {
        path: "/discount/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "PRO_DISCOUNT_EDIT",
        component: DiscountEdit,
    },

    // end#DisCount


    //Page
    {
        path: "/page",
        exact: true,
        name: "Danh sách Page",
        function: "MD_PAGE_VIEW",
        component: Page,
    },
    {
        path: "/page/add",
        exact: true,
        name: "Thêm mới",
        function: "MD_PAGE_ADD",
        component: PageAdd,
    },
    {
        path: "/page/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "MD_PAGE_VIEW",
        component: PageDetail,
    },
    {
        path: "/page/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "MD_PAGE_EDIT",
        component: PageEdit,
    },

    // end#Page

    //WithdrawMoney
    {
        path: "/withdraw-request",
        exact: true,
        name: "Danh sách yêu cầu rút tiền",
        function: "WA_WITHDRAWREQUEST_VIEW",
        component: WithdrawRequest,
    },
    {
        path: "/withdraw-request/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "WA_WITHDRAWREQUEST_REVIEW",
        component: WithdrawRequestDetail,
    },
    // end#WithdrawMoney

    //Affiliate
    {
        path: "/affiliate",
        exact: true,
        name: "Quản lý đối tác Affiliate",
        function: "AFF_AFFILIATE_VIEW",
        component: Affiliate,
    },
    {
        path: "/affiliate/add",
        exact: true,
        name: "Thêm mới",
        function: "AFF_AFFILIATE_ADD",
        component: AffiliateAdd,
    },
    {
        path: "/affiliate/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "AFF_AFFILIATE_EDIT",
        component: AffiliateEdit,
    },
    {
        path: "/affiliate-request/review/:id",
        exact: true,
        name: "Duyệt",
        function: "AFF_AFFILIATE_REVIEW",
        component: AffiliateReview,
    },

    {
        path: "/affiliate/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "AFF_AFFILIATE_VIEW",
        component: AffiliateDetail,
    },

    {
        path: "/affiliate-request",
        exact: true,
        name: "Danh sách đăng ký Affiliate",
        function: "AFF_AFFILIATE_REVIEW",
        component: AffiliateRequest,
    },
    // end#Affiliate

    //PolicyCommision
    {
        path: "/policy-commision",
        exact: true,
        name: "Quản lý chính sách",
        function: "AFF_POLICYCOMMISION_VIEW",
        component: AffPolicyCommision,
    },
    {
        path: "/policy-commision/add",
        exact: true,
        name: "Thêm mới",
        function: "AFF_POLICYCOMMISION_ADD",
        component: AffPolicyCommisionAdd,
    },
    {
        path: "/policy-commision/edit/:id",
        exact: true,
        name: "Chỉnh sửa",
        function: "AFF_POLICYCOMMISION_EDIT",
        component: AffPolicyCommisionEdit,
    },
    {
        path: "/policy-commision/detail/:id",
        exact: true,
        name: "Chi tiết",
        function: "AFF_POLICYCOMMISION_VIEW",
        component: AffPolicyCommisionDetail,
    }
    // end#PolicyCommision

];

export default routes;
